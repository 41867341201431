@font-face {
    font-family: 'aljazeer';
    src: url('./fonts/Al-Jazeera-Arabic-Regular.ttf');
  }
  @font-face {
    font-family: 'titi';
    src: url('./fonts/titi.ttf');
  }
.menu{
  font-weight: bold;
background: #871cc5;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #3c1053, #ad5389);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #3c1053, #ad5389); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
border-radius: 10px 10px 10px 10px;
-webkit-border-radius: 10px 10px 10px 10px;
-moz-border-radius: 10px 10px 10px 10px;
}

.cardfil{
    background: #42275a;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #734b6d, #42275a);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #734b6d, #42275a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
.titrefil{
color: white;
font-family:titi, Aljazeera;
background: #ad5389;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #3c1053, #ad5389);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #3c1053, #ad5389); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
border-radius: 57px 57px 57px 57px;
-webkit-border-radius: 57px 57px 57px 57px;
-moz-border-radius: 57px 57px 57px 57px;
    }
  .departement_form{
    font-family:titi, Aljazeera;
    background: #3c1053;
    color: white;
    font-size: 14pt; 
  }
  .departement_label{
    font-family:titi, Aljazeera;
    color: #3c1053;;
    font-size: 14pt; 
  }
  .departement_bg{
    background: #D3CCE3;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E9E4F0, #D3CCE3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
  .tab{
    font-family:titi, Aljazeera;
    font-size: 12pt;
  }
  .inputPlanning{
    font-family:titi, Aljazeera;
   font-size: 14pt;
   background: #C9D6FF;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
    .year{
      font-family:titi, Aljazeera;
   font-size: 24pt;
   background: #C9D6FF;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }

    input[type="text"].filtreplanning, input[type="text"].filtreplanning:focus{
      color: antiquewhite;
      font-family:titi, Aljazeera;
      font-size: 14pt;
      background: #8E2DE2;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
    select.filtreplanning{
      color: antiquewhite;
      font-family:titi, Aljazeera;
      font-size: 14pt;
      background: #8E2DE2;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
    .trait {
      color: #7816ce;
      border: 4px solid;
    }

    .update_label{
      font-family:titi, Aljazeera;
      color: #340255;
      font-size: 14pt; 
    }

    input.modif, input.modif:focus{
      color: antiquewhite;
      font-family:titi, Aljazeera;
      font-size: 14pt;
      background: #8E2DE2;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
    .myselect {
      font-family:titi, Aljazeera;
      background: #8E2DE2;
      color: antiquewhite;
      font-size: 14pt; 
    }
    textarea.textareaplanning {
      font-family:titi, Aljazeera;
      background: #8E2DE2;
      color: antiquewhite;
      font-size: 14pt; 
      padding: 10px;
      max-width: 100%;
      line-height: 1.5;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-shadow: 1px 1px 1px #999;
    }
    .text_bold{
      font-weight: bold;
      font-size: 14pt;
      color: #110130;
    }
